export const FETCH_NEWS = "FETCH_NEWS";
export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const FETCH_REDIRECT_MODAL = "FETCH_REDIRECT_MODAL";
export const CLOSE_REDIRECT_MODAL = "CLOSE_REDIRECT_MODAL";
export const FETCH_NEDELOV_MODAL = "FETCH_NEDELOV_MODAL";
export const CLOSE_NEDELOV_MODAL = "CLOSE_NEDELOV_MODAL";
export const FETCH_RIM_BUILDING_IMAGES = "FETCH_RIM_BUILDING_IMAGES";
export const FETCH_TEAM_MODAL = "FETCH_TEAM_MODAL";
export const CLOSE_TEAM_MODAL = "CLOSE_TEAM_MODAL";
export const FETCH_TEAM = "FETCH_TEAM";
export const FETCH_EXHIBITIONS = "FETCH_EXHIBITIONS";
export const FETCH_EXHIBITIONS_ERROR = "FETCH_EXHIBITIONS_ERROR";
export const RESET_FETCH_EXHIBITIONS = "RESET_FETCH_EXHIBITIONS";
export const FETCH_ROUTES = "FETCH_ROUTES";
export const FETCH_SERVICES = "FETCH_SERVICES";
export const FETCH_COLLECTIONS_MAIN = "FETCH_COLLECTIONS_MAIN";
export const FETCH_COLLECTIONS_VIRTUAL = "FETCH_COLLECTIONS_VIRTUAL";
export const FETCH_COLLECTIONS_BYTYPE = "FETCH_COLLECTIONS_BYTYPE";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_NEWS_ERROR = "FETCH_NEWS_ERROR";
export const RESET_FETCH_NEWS = "RESET_FETCH_NEWS";
export const FETCH_NEWS_ERROR_RESET = "FETCH_NEWS_ERROR_RESET";
export const FETCH_EXHIBITIONS_ERROR_RESET = "FETCH_EXHIBITIONS_ERROR_RESET";
export const FETCH_EXHIBITION_ARTICLE = "FETCH_EXHIBITION_ARTICLE";
export const FETCH_EXHIBITION_ARTICLE_ERROR = "FETCH_EXHIBITION_ARTICLE_ERROR";
export const FETCH_COLLECTIONS_BYTYPE_ERROR = "FETCH_COLLECTIONS_BYTYPE_ERROR";
export const FETCH_ITEM_FROM_COLLECTION = "FETCH_ITEM_FROM_COLLECTION";
export const FETCH_ITEM_FROM_COLLECTION_ERROR =
  "FETCH_ITEM_FROM_COLLECTION_ERROR";
export const FETCH_COLLECTION_DESCRIPTION = "FETCH_COLLECTION_DESCRIPTION";
export const FETCH_COLLECTION_DESCRIPTION_ERROR = "FETCH_COLLECTION_DESCRIPTION_ERROR";
